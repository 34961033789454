import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import 'rc-datepicker/lib/style.css';
import CurrencyFormat from 'react-currency-format';
import remSep from "string-remove-thousand-separators";

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        stay_id : '',
        transportation_id : '',
        electrical_power_id : '',
        school_distance_hour : '',
        school_distance_minute : '',
        hp : '',
        email : '',
        child : '',
        total_children : '',
        // blood_type_id : '',
        sibling_dependent:'',
        bill_pln:'',
        // height : '',
        // weight : '',
        // head_length : '',
        school_from_id : '',
        school_from_name : '',
        school_from_address : '',
        study_year :'',
        spp : '',
    }
  }

  validateForm() {
    return this.state.stay_id.length > 0 
    && this.state.bill_pln.length > 0
    && this.state.sibling_dependent.length > 0
    && this.state.transportation_id.length > 0
    && this.state.electrical_power_id.length > 0
    && this.state.school_distance_hour.length > 0
    && this.state.school_distance_minute.length > 0
    && this.state.hp.length > 0
    && this.state.email.length > 0
    && this.state.child.length > 0
    && this.state.total_children.length > 0
    // && this.state.blood_type_id.length > 0
    // && this.state.weight.length > 0
    // && this.state.head_length.length > 0
    && this.state.school_from_id.length > 0
    && this.state.school_from_name.length > 0
    && this.state.school_from_address.length > 0
    && this.state.study_year.length > 0;
  }

  componentDidMount() {
    if(localStorage.getItem('reg3')){
      this.setState(JSON.parse(localStorage.getItem('reg3')));
      console.log(localStorage.getItem('reg3'))
    }
  }

  createSelectStay() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmStay;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectTransportation() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmTransportation;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectElectricalPower() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmElectricalPower;
    console.log(JSON.parse(localStorage.getItem('DATA_PARAMETER')))
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectBloodtype() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmBloodType;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  createSelectSchoolfrom() {
    let items = [];
    let params = JSON.parse(localStorage.getItem('DATA_PARAMETER')).prmSchoolForm;
    for (let i = 0; i < params.length; i++) {             
         items.push(<option key={i} value={params[i].id}>{params[i].name}</option>);   
    }
    return items;
  }

  onChange = e => {
    var a = e.target.value;
    a = a.replace("/", "");
    a = a.replace('"', "");
    this.setState({ [e.target.name]: a })
  }

  onChangeNom = e => this.setState({ [e.target.name]: remSep(e.target.value) })

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit = event => {
    event.preventDefault();    

    if(this.state.stay_id===""){
      global.swal("Info", "Harap pilih tinggal dengan", "info");
    }else if(this.state.transportation_id===""){
      global.swal("Info", "Harap pilih moda transportasi", "info");
    }else if(this.state.blood_type_id===""){
      global.swal("Info", "Harap pilih golongan darah", "info");
    }else if(this.state.electrical_power_id===""){
      global.swal("Info", "Harap pilih golongan listrik", "info");
    }else if(this.state.school_from_id===""){
      global.swal("Info", "Harap pilih sekolah asal", "info");
    }else{

      localStorage.setItem('reg3', JSON.stringify(this.state));
      console.log(localStorage.getItem('reg3'));
      window.location.href="/pendaftaran4";
    }
  }

  handleBack = () => {
    window.location.href="/pendaftaran2";
  }


  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Pendaftaran Step 3</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form onSubmit={this.handleSubmit}>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Tinggal Dengan *</Form.Label>
                            <Form.Control as="select" name="stay_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.stay_id} required>
                              <option value="">-Pilih Tinggal Dengan-</option>
                              {this.createSelectStay()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Moda Transportasi *</Form.Label>
                            <Form.Control as="select" name="transportation_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.transportation_id} required>
                              <option value="">-Pilih Moda Transportasi-</option>
                              {this.createSelectTransportation()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Jarak Tempuh (KM) *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="school_distance_hour" 
                              onChange={this.onChange} 
                              value={this.state.school_distance_hour}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Waktu Tempuh (Menit) *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="school_distance_minute" 
                              onChange={this.onChange} 
                              value={this.state.school_distance_minute}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form> 
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nomor Handphone (No HP Anak atau Orang Tua)*</Form.Label>
                            <Form>
                            <Form.Label>+62</Form.Label>
                            <CurrencyFormat 
                              input="number"
                              name="hp" 
                              onChange={this.onChange} 
                              value={this.state.hp}
                              maxLength="13"
                              style={{ width : '95%', marginLeft:10}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Email Anak *</Form.Label>
                            <Form.Control 
                              type="email"
                              name="email"
                              maxLength="100"
                              value={this.state.email}
                              onChange={this.onChange}  required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Anak ke *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="child" 
                              onChange={this.onChange} 
                              value={this.state.child}
                              maxLength="25"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Dari bersaudara *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="total_children" 
                              onChange={this.onChange} 
                              value={this.state.total_children}
                              maxLength="2"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Saudara Yang Masih Menjadi Tanggungan Orang Tua ... Orang * (isi hanya angka, jika tidak ada diisi dengan angka 0)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="sibling_dependent" 
                              onChange={this.onChange} 
                              value={this.state.sibling_dependent}
                              maxLength="2"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          {/* <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Golongan Darah *</Form.Label>
                            <Form.Control as="select" name="blood_type_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.blood_type_id} required>
                              <option value="">-Pilih Golongan Darah-</option>
                              {this.createSelectBloodtype()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Tinggi (cm) *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="height" 
                              onChange={this.onChange} 
                              value={this.state.height}
                              maxLength="5"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Berat (kg) *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="weight" 
                              onChange={this.onChange} 
                              value={this.state.weight}
                              maxLength="5"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Lingkar Kepala (cm) *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="head_length" 
                              onChange={this.onChange} 
                              value={this.state.head_length}
                              maxLength="5"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group> */}
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Sekolah Asal *</Form.Label>
                            <Form.Control as="select" name="school_from_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.school_from_id} required>
                              <option value="">-Pilih Sekolah Asal-</option>
                              {this.createSelectSchoolfrom()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Sekolah Asal (Sesuai Identitas Raport)*</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="school_from_name"
                              maxLength="50"
                              value={this.state.school_from_name}
                              onChange={this.onChange}  required/>
                          </Form.Group> 
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Alamat Sekolah Asal (Sesuai Identitas Raport)*</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="school_from_address"
                              maxLength="100"
                              value={this.state.school_from_address}
                              onChange={this.onChange}  required/>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Lama Belajar (Dalam Tahun)*</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="study_year" 
                              onChange={this.onChange} 
                              value={this.state.study_year}
                              maxLength="5"
                              style={{ width : '100%'}} 
                              required/>
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang SPP Sekolah Asal (isi hanya angka jangan diberi titik dan koma, jika tidak ada diisi dengan angka 0)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="spp" 
                              onChange={this.onChangeNom} 
                              value={this.state.spp}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Daya Listrik *</Form.Label>
                            <Form.Control as="select" name="electrical_power_id" onChange={this.onDropdownSelected.bind(this)} value={this.state.electrical_power_id} required>
                              <option value="">-Pilih Golongan Listrik-</option>
                              {this.createSelectElectricalPower()}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Biaya rata-rata listrik per bulan *</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="bill_pln" 
                              onChange={this.onChangeNom} 
                              value={this.state.bill_pln}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}}
                              required />
                            </Form>
                          </Form.Group>
                          <Form.Group>
                          <div className='rowHorizontal'>
                            <Button onClick={this.handleBack} block style={{margin:5, height:50}}>Prev</Button>
                            <Button type="submit" disabled={!this.validateForm()} block style={{margin:5, height:50}} >Next</Button>
                          </div>
                          </Form.Group>
                        </Form>
                    </div>


                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;