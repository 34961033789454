import React, { Component } from 'react';
import {Form, Breadcrumb, Button} from 'react-bootstrap';
import 'moment/locale/id.js';
import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import remSep from "string-remove-thousand-separators";

const moment = require('moment');

class Pendaftaran extends Component {

  constructor(props){
    super(props);

    this.state={
        student_id : '',
        is_show : false,
        note_verification : '',
        upp : '',
        potongan : '0',
        nominal : '',
        dp :'',
        termin : '1',
        angsuran : '',
        us : '',
        uk :'20000',
        ukg:'105000',
        pomg:'35000',
        totalus:'',

    }
  }


  handleClick = param => e => {
    e.preventDefault();
    
    localStorage.setItem('student_id', param)
    window.open("/detailstudent", '_blank')
  }
  getFiles(files){
    this.setState({ encrypt_64: files[0].base64, original_name: files[0].name,  })
  }

  componentDidMount() {
    this.setState({student_id: localStorage.getItem('DATA_JALUR').split('-')[0]});
    
    fetch(global.variable.LINK_API + 'student/detail/'+ localStorage.getItem('DATA_JALUR').split('-')[0],
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'),
      },
      //body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        this.setState(resData.data[0]);
        this.setState({is_show : true});
         console.log(this.state);
         if(this.state.data_bayar_calc && this.state.data_bayar_calc.length){
        let potongan2 = this.state.data_bayar_calc[0].nominal * this.state.data_bayar_calc[0].potongan / 100

        let nominal2 = this.state.data_bayar_calc[0].nominal - potongan2;
        let angsur = ((nominal2 - this.state.data_bayar_calc[0].dp) / this.state.data_bayar_calc[0].termin).toFixed(0); 
        let total = (this.state.data_bayar_calc[1].nominal + this.state.data_bayar_calc[2].nominal + this.state.data_bayar_calc[3].nominal + this.state.data_bayar_calc[4].nominal)

      this.setState({upp:this.state.data_bayar_calc[0].nominal, potongan:this.state.data_bayar_calc[0].potongan,nominal:nominal2,
                     dp:this.state.data_bayar_calc[0].dp, termin:this.state.data_bayar_calc[0].termin, angsuran:angsur,
                     us:this.state.data_bayar_calc[1].nominal,  uk:this.state.data_bayar_calc[2].nominal,  ukg:this.state.data_bayar_calc[3].nominal,
                     pomg:this.state.data_bayar_calc[4].nominal, totalus:total})}

      if(this.state.data_bayar_calc[0]){
        this.setState({potongan:this.state.data_bayar_calc[0].potongan})
      }else {
        if(this.state.school_from.id == '1'){
          this.setState({potongan:'0'})
        }
      }
      }
       
    })
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value })

  onChangeNom = e => this.setState({ [e.target.name]: remSep(e.target.value) })


  onChangePotongan = e => {let potongan2 = this.state.upp * this.state.potongan / 100
                          let nominal2 = this.state.upp - potongan2;
                          if(this.state.dp < nominal2 * 0.3){
                            if(e.target.name==='upp'||e.target.name==='potongan'){
                              this.setState({dp: nominal2 * 0.3})
                            }
                          }
                          this.setState({nominal:nominal2},
                           ()=>{
                            let angsur = (this.state.nominal-this.state.dp) / this.state.termin;
                            angsur = angsur.toFixed(0);
                            console.log("termin" + this.state.termin)
                            console.log("nominal" + this.state.nominal)
                            console.log("dp" + this.state.dp)
                            console.log(angsur)
                            this.setState({angsuran:angsur})
                            //this.setState({us:this.state.nominal * 0.1})
                            }
                          );

                          
                          }
  onChangeTotal = e => { let total = parseInt(this.state.us) + parseInt(this.state.uk) + parseInt(this.state.ukg) + parseInt(this.state.pomg);
                        console.log(this.state.uk)
                         this.setState({totalus:total})
                        }
  

  onDropdownSelected(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleTolak = ()  => {
    global.swal({
      text: 'Alasan tidak sepakat',
      content: "input",
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {text: 'Submit', 
        closeModal: false,}
      ],
    })
    .then((confirm) => {
      let errorMessage =  { code : 403, message : 'cancel' };
      if (!confirm) throw errorMessage;
      const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 98, note_reject : confirm}
      console.log(JSON.stringify(data))
      return fetch(global.variable.LINK_API + 'flow/update',
      {
        method: 'post',
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
        },
        body: JSON.stringify(data), 
      })
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Tolak', "success")
        .then((value) => {
          window.location.href="/wawancara";
        });
      }
       
    })
    .catch(err => {
      console.log(err)
      if (err) {
        if(err.code!==403){
          global.swal("Error", "Koneksi ke server gagal.", "info")
        }
      } else {
        global.swal.stopLoading();
        global.swal.close();
      }
    });

  }
 

  handleSave = param => event => {
    event.preventDefault();
    
    if(this.state.upp === "")
      {global.swal("Error", "UPP harap diisi", "info")}
    else if(this.state.potongan ==="")
      {global.swal("Error", "Potongan harap diisi", "info")}
    else if(this.state.nominal ==="")
      {global.swal("Error", "Nominal harap diisi", "info")}
    else if(this.state.dp ==="")
      {global.swal("Error", "DP harap diisi", "info")}
    else if(this.state.termin ==="")
      {global.swal("Error", "Termin harap diisi", "info")}
    else if(this.state.angsuran ==="")
      {global.swal("Error", "Angsuran harap diisi", "info")}
    else if(this.state.us ==="")
      {global.swal("Error", "Uang Sekolah harap diisi", "info")}
    else if(this.state.uk ==="")
      {global.swal("Error", "Uang Komputer harap diisi", "info")}
    else if(this.state.ukg ==="")
      {global.swal("Error", "Uang Kegiatan harap diisi", "info")}
    else if(this.state.pomg ==="")
      {global.swal("Error", "Uang Komite (POMG) harap diisi", "info")}

    else{

    // if(this.state.dp < this.state.nominal * 0.3)
    //   {global.swal("Error", "Uang DP harus lebih dari 30%", "info")}
    //console.log("state 1 " + JSON.stringify(this.state));
    let item = []

    //console.log(this.state.encrypt_64);
    let items =  Object.create({});
      items.id = "45";
      items.name = "UPP";
      items.nominal = this.state.upp;
      items.potongan = this.state.potongan;
      items.dp = this.state.dp;
      items.termin= this.state.termin;
      item.push(items);

      items =  Object.create({});
      items.id = "25";
      items.name = "Uang Sekolah";
      items.nominal = this.state.us;
      items.potongan = 0;
      items.dp = 0;
      items.termin= 0;
      item.push(items);

      items =  Object.create({});
      items.id = "26";
      items.name = "Uang Komputer";
      items.nominal = this.state.uk;
      items.potongan = 0;
      items.dp = 0;
      items.termin= 0;
      item.push(items);

      items =  Object.create({});
      items.id = "27";
      items.name = "Uang Kegiatan";
      items.nominal = this.state.ukg;
      items.potongan = 0;
      items.dp = 0;
      items.termin= 0;
      item.push(items);

      items =  Object.create({});
      items.id = "35";
      items.name = "Uang Komite(POMG)";
      items.nominal = this.state.pomg;
      items.potongan = 0;
      items.dp = 0;
      items.termin= 0;
      item.push(items);
    
    console.log(JSON.stringify(item));
    const data2 = { data:JSON.stringify(item),student_id:this.state.student_id,note_verification:this.state.note_verification,interview_status:param}
    console.log(data2);
     fetch(global.variable.LINK_API + 'wawancara/store',
          {
            method: 'post',
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
            },
            body: JSON.stringify(data2), 
          })
          .then(response =>  response.json())
          .then(resData => {
            if(resData.message==="Success"){
              
                global.swal("Success", resData.message, "success")
                .then((value) => {
                  window.location.href="/wawancaradetail";
                  
                });
              
              
            }
            console.log(resData);
            
          })

  }
}

handlePrint =  e => {
    e.preventDefault();
     

    localStorage.setItem('flow_id',  8)
    localStorage.setItem('upp',  this.state.upp)
    localStorage.setItem('spp',   this.state.us)
    localStorage.setItem('kegiatan',  (this.state.uk+this.state.ukg+this.state.pomg))
    localStorage.setItem('totalus',   this.state.totalus)
    localStorage.setItem('potongan',  this.state.potongan)
    localStorage.setItem('finalupp',  this.state.nominal)

    window.location.href="/cetakskfinansial";
  }


  // handleNext = () => {

  //   window.location.href="/verifikasi";
  // }

  download= param => e => {
    e.preventDefault();
    console.log(param)
    window.open("/image/"+param, "_blank", 'location=yes,height=600,width=600,scrollbars=yes,status=yes')

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  }

  form1(){
    return (
      <div>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Jalur</Form.Label>
          <Form.Control 
            type="Text"
            name="fullname"
            maxLength="100"
            value={this.state.registration_type.name}
            disabled/>
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Lengkap  (Nama siswa dapat di klik untuk melihat detail data siswa)</Form.Label>
          <Form.Control 
            type="Text"
            name="fullname"
            maxLength="100"
            onClick = {this.handleClick(this.state.id)}
            value={this.state.fullname}
            style={{color : 'blue'}}
            />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Panggilan</Form.Label>
          <Form.Control 
            type="Text"
            name="nickname"
            readOnly 
            maxLength="100"
            value={this.state.nickname}
            disabled/>
        </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Alamat</Form.Label>
          <Form.Control 
            type="Text"
            name="address"
            maxLength="200"
            value={this.state.address}
            disabled />
        </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>RT</Form.Label>
          <Form.Control 
            type="Text"
            name="rt"
            maxLength="5"
            value={this.state.rt}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>RW</Form.Label>
          <Form.Control 
            type="Text"
            name="rw"
            maxLength="5"
            value={this.state.rw}
            disabled  />
        </Form.Group>
           <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kelurahan</Form.Label>
          <Form.Control 
            type="Text"
            name="sub_district"
            maxLength="100"
            value={this.state.sub_district}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kecamatan</Form.Label>
          <Form.Control 
            type="Text"
            name="district"
            maxLength="100"
            value={this.state.district}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kabupaten/Kota</Form.Label>
          <Form.Control 
            type="Text"
            name="sub_province"
            maxLength="100"
            value={this.state.sub_province}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Tinggal Dengan</Form.Label>
          <Form.Control 
            type="Text"
            name="stay_id"
            maxLength="2"
            value={this.state.stay.name}
            disabled />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Moda Transportasi</Form.Label>
          <Form.Control 
            type="Text"
            name="transportation"
            maxLength="2"
            value={this.state.transportation_id}
            disabled />
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>No. Handphone</Form.Label>
          <Form.Control 
            type="Text"
            name="hp"
            maxLength="2"
            value={this.state.hp}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Anak ke</Form.Label>
          <Form.Control 
            type="Text"
            name="child"
            maxLength="2"
            value={this.state.child}
            disabled  />
        </Form.Group>
          <Form.Label>Sekolah Asal</Form.Label>
          <Form.Control 
            type="Text"
            name="school_from"
            maxLength="2"
            value={this.state.school_from.name}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Nama Sekolah Asal</Form.Label>
          <Form.Control 
            type="Text"
            name="school_from_name"
            maxLength="2"
            value={this.state.school_from_name}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Alamat Sekolah Asal</Form.Label>
          <Form.Control 
            type="Text"
            name="school_from_address"
            maxLength="2"
            value={this.state.school_from_address}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Uang SPP Sekolah Asal</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.spp} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form>     
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Daya Listrik</Form.Label>
          <Form.Control 
            type="Text"
            name="electrical_power_id"
            maxLength="2"
            value={this.state.electrical_power?this.state.electrical_power.name:''}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Biaya rata-rata listrik per bulan</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.bill_pln} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form> 
        </Form.Group>
          <Form.Group></Form.Group>
      </div>
    );
  }



  form4(){
    let guardian = this.state.guardians;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) { 
      showitem.push(
        <div key={guardian[i].nik}>
          <hr/>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Orang Tua </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].guardian_name} </Form.Label>
            
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama Lengkap </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].fullname} </Form.Label>
          </div>
           <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Pekerjaan </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].occupation_name} </Form.Label>
          </div>
           <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Penghasilan </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>
            <CurrencyFormat value={guardian[i].salary} displayType={'text'} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled /> </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Alamat </Form.Label>
            <Form.Label className="col-md-3" style={{margin:5,}}>{guardian[i].address} </Form.Label>
          </div>
          
        </div>
        );
      }

      return showitem;

  }

  form5(){
    
    let guardian = this.state.sibling;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) { 
      showitem.push(
        <div key={guardian[i].name}>
          <hr/>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].name} </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Kelas </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].class} </Form.Label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form6(){
    
    let guardian = this.state.achieves;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) { 
      showitem.push(
        <div key={guardian[i].name}>
          <hr/>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Jenis </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].achieve_type_name } </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Nama Prestasi </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].name} </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Penyelenggara </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].promoter} </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Tingkat </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].achieve_rank_name} </Form.Label>
          </div>
          <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Peringkat </Form.Label>
            <Form.Label className="col-md-6" style={{margin:5,}}>{guardian[i].rank} </Form.Label>
          </div>
        </div>
      );
    }

    return showitem;
  }



  formdokumen(){
    let guardian = this.state.upload_documents;
    let showitem = [];
    //console.log(guardian);

    for (let i = 0; i < guardian.length; i++) { 
      showitem.push(
        <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>{this.state.upload_documents[i].document_upload_name}</Form.Label>
        <div>
        {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}
        
        <Link  target="_blank" onClick={this.download(this.state.upload_documents[i].id)}>Lihat Dokumen</Link>
        </div>
      </Form.Group>
      )
    }
    return showitem;
  }

  formbukti(){
    
    return(
      /*<Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_transaction.encrypt_64} thumbnail />*/
      
      <Link  target="_blank" onClick={this.download(this.state.upload_transaction.id)}>Lihat Bukti Bayar</Link>

    );
  }

  handleTest(){
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 4, note_verification : this.state.note_verification }

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        let m = moment();  
        m = moment(resData.data.schedule_test_at, 'YYYY-MM-DD').format('DD-MM-YYYY');
        global.swal("Success", 'Jadwal test adalah ' + m + '\n Pada pukul 13.00 – 15.00 WIB \nDi ruang ' +  resData.data.classroom_id, "success")
        .then((value) => {
          window.location.href="/verifikasibuktibayar";
        });
      }
       
    })
  }

  
  handleConfirm= event =>{
    const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 100, note_verification : this.state.note_verification}

    fetch(global.variable.LINK_API + 'flow/update',
    {
      method: 'post',
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer '+ global.cookies.get('access_token'), 
      },
      body: JSON.stringify(data), 
    })
    .then(response =>  response.json())
    .then(resData => {
      if(resData.message==="Success"){
        console.log(resData);
        
        global.swal("Success", 'Berhasil Diterima', "success")
        .then((value) => {
          window.location.href="/wawancara";
        });
      }
       
    })
  }



  render() {
    return (
      <div className="content-wrapper">
          <section className="content-header">
              <div className="row">
                  <div className="col-md-12">
                      <div className="box">
                      <div className="divContainter">
                        <Breadcrumb>
                          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                          <Breadcrumb.Item active>Wawancara</Breadcrumb.Item>
                        </Breadcrumb>
                        
                        <Form >
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nama Siswa</Form.Label>
                            <Form.Control 
                              type="Text"
                              name="jalur"
                              value={localStorage.getItem('DATA_JALUR')}
                              disabled />
                          </Form.Group>
                          {this.state.is_show ? 
                          this.form1()
                           : ''}

                          {this.state.is_show?
                          <div>
                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Data Orang Tua</Form.Label>
                          </div>
                          : ''}

                          {this.state.is_show ? 
                          this.form4()
                           : ''}

                          
                          {this.state.is_show?
                          <div>
                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Saudara yang masih bersekolah di {this.state.nama_sekolah}</Form.Label>
                          </div>
                          : ''}

                          {this.state.is_show ? 
                          this.form5()
                           : ''}
        
                          <div>
                          <hr/>
                          <Form.Label style={{ fontSize: 20, textDecorationLine: 'underline'}} >Catatan Tentang Pendaftar</Form.Label>
                          </div>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Catatan</Form.Label>
                            <Form.Control 
                              as="textarea" rows="5"
                              name="note_verification"
                              value={this.state.note_verification}
                              onChange={this.onChange} 
                              />
                          </Form.Group>
                          
                          
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>UPP</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="upp" 
                              onChange={this.onChangeNom} required
                              onBlur={this.onChangePotongan}
                              value={this.state.upp}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                           <Form.Group controlId="exampleForm.ControlInput">
                            <Form.Label>Potongan(%)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="potongan" 
                              onChange={this.onChangeNom} 
                              onBlur={this.onChangePotongan} 
                              value={this.state.potongan}
                              maxLength="3"  thousandSeparator={true}
                              style={{ width : '100%'}} required />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Nominal</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="nominal" 
                              onChange={this.onChangeNom}
                              onBlur={this.onChangePotongan} 
                              value={this.state.nominal}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%', color : 'white', backgroundColor : 'red'}}
                              disabled />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>DP</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="dp" 
                              onChange={this.onChangeNom}
                              onBlur={this.onChangePotongan} 
                              value={this.state.dp}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Termin</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="termin" 
                              onChange={this.onChangeNom} 
                              onBlur={this.onChangePotongan}
                              value={this.state.termin}
                              maxLength="2"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Angsuran</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="angsuran"
                              decimalScale='0'
                              onChange={this.onChangeNom}
                              onBlur={this.onChangePotongan} 
                              value={this.state.angsuran}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>

                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang Sekolah</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="us" 
                              onChange={this.onChangeNom} 
                              onBlur={this.onChangeTotal} 
                              value={this.state.us}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang Komputer</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="uk" 
                              readOnly
                              onChange={this.onChangeNom} 
                              value={this.state.uk}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang Kegiatan</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="ukg" 
                              readOnly
                              onChange={this.onChangeNom} 
                              value={this.state.ukg}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang Komite(POMG)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="pomg"
                              readOnly 
                              onChange={this.onChangeNom} 
                              value={this.state.pomg}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>SPP per Bulan</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="totalus"
                              readOnly
                              onChange={this.onChangeNom} 
                              onBlur={this.onChangeTotal} 
                              value={this.state.totalus}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%', color : 'white', backgroundColor : 'red'}} 
                              disabled/>
                            </Form>
                          </Form.Group>
                           <Form.Group>
                          <div className='rowHorizontal'>
                            <Button disabled = {this.state.interview_status_id==='1'}onClick={this.handleTolak} block style={{margin:5, height:50, backgroundColor:'red'}}>Tidak Sepakat</Button>
                            <Button disabled = {this.state.interview_status_id==='1'} onClick={this.handleSave('2')} type="submit" block style={{margin:5, height:50, backgroundColor:'#e6e600'}} >Banding</Button>
                            <Button onClick={this.handleSave('1')} block style={{margin:5, height:50, backgroundColor:'#33cc33'}} >Sepakat</Button>
                            
                          </div>
                          
                          {this.state.data_bayar_calc && this.state.interview_status_id ==='1' && this.state.data_bayar_calc.length?
                          <div>
                          <div className='rowHorizontal'>
                            
                            <Button onClick={this.handlePrint}  block style={{margin:5, height:50}} >Print</Button>
                            
                          </div>
                          
                         </div>:''}
                          </Form.Group>
                        </Form>
                       </div>




                      </div>
                  </div>
              </div>
          </section>
      </div>
      
    );
  }
}

export default Pendaftaran;